import { style, textAlign } from "@mui/system";
import React, { ReactNode } from "react";
import Loader, {
  Bars,
  Circles,
  Oval,
  RotatingLines,
  TailSpin,
  Vortex,
} from "react-loader-spinner";

interface loaderProps {
  children?: ReactNode;
  isLoading?: boolean;
  height?: number;
  width?: number;
  color?: string;
  className?: string;
}

const Loading: React.FC<loaderProps> = ({
  children,
  isLoading,
  height,
  width,
  color,
  className = "", 
}) => {
  return (
    <div className="w-full">
      {/* <div className={`${isLoading ? "absolute inset-0 bg-black opacity-50" : ""}`}></div> */}
      {isLoading && (
        <div
          className={`${isLoading && "loading"} ${className}`}
          style={{ textAlign: "center" }}
        >

          <TailSpin
            visible={true}
            height="60"
            width="80"
            color="red"
            ariaLabel="tail-spin-loading"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      )}
      {children}
    </div>
  );
};

export default Loading;
